$listing-item-header-font-size: 2rem !default;
$listing-item-details-font-size: $font-size-base !default;
$listing-item-body-font-size: $font-size-base !default;

.listing-item {
  &:not(:last-child):after {
    content: "";
    display: block;
    margin: 1rem 0;
    border-bottom: 1px solid $dark;
  }
}

.listing-item-header {
  font-size: $listing-item-header-font-size;
}

.listing-item-details {
  font-size: $listing-item-details-font-size;
}

.listing-item-body {
  font-size: $listing-item-body-font-size;
}