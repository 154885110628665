.gform_wrapper.gravity-theme {
  input[type="text"],
  textarea,
  select,
  input[type="tel"],
  input[type="email"] {
    border: none;
    background: $gray-100;
  }

  input[type="submit"] {
    @extend .btn, .btn-primary;
  }

  .gfield_radio {
    .gchoice {
      position: relative;

      input {
        width: 1px;
        height: 1px;
        overflow: hidden;
        position: absolute;
        left: -9999px;
        outline: none;
      }
      label {
        position: relative;
        padding-left: 2.5rem;
        line-height: 2rem;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          border-width: 2px;
          border-color: transparent;
          border-style: solid;
          background: $gray-100;
          content: '';
        }
      }
      input:focus + label {
        &::before {
          border-color: $blue;
        }
      }
      input:checked + label {
        &::after {
          position: absolute;
          top: (4rem/16);
          left: (4rem/16);
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%; 
          background: $blue;
          content: '';
        }
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .gfield_radio {
    .gchoice {
      display: inline-block;
      label {
        white-space: nowrap;
      }
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.form-container {
  margin-bottom: 0;
  transition: margin-bottom 0.1s linear;
}