hr {
  height: 2px;
  border: none;
  background: $dk-gray;
}

.wf-block-hr {
  .wf-block-appearance {
    padding: 0;
  }
}