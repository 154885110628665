.nav.footer-util {
    .nav-item {
        padding-left: 10px;
        padding-right: 10px;
    }
    li:nth-child(n+1):nth-child(-n+2) {
        &:after {
            position: absolute;
            bottom: 6px;
            content: "";
            right: -1px;
            height: 16px;
            width: 1px;
            background-color: $white;
        }
    }
    li {
        position: relative;
    }
    li a {
        color: $white;
        font-weight: 300;
    }
}