.footer-navigation {
    .nav {
        justify-content: space-between;
    }
    padding-top: 2rem;
    ul li.nav-item {
        width: 100%;
        text-align: center;
        @include media-breakpoint-up(md) {
            text-align: left;
            width: unset;
        }
        @include media-breakpoint-up(lg) {
            max-width: 20%;
        }
    }
    ul li.nav-item a {
        color: $white;
    }
    ul.sub-nav {
        display: none;
        flex-direction: column;
        li.nav-item a {
            font-weight: 300;
        }
        @include media-breakpoint-up(lg) {
            display: block;
            li.nav-item {
                max-width: 100%;
            }
        }
    }
    .nav-link {
        padding-left: 0;
        padding-right: 0;
    }
}