$search-box-input-width: 12rem !default;
$search-box-input-border-color: $dark !default;
$search-box-button-width: 2.5rem !default;
$search-box-button-bg-color: $dark !default;
$search-box-button-image: "../images/icons/magnifying-glass-white.png" !default;

.search-box-input {
  width: $search-box-input-width !important;
  border-color: $search-box-input-border-color;
}

.search-box-button {
  width: $search-box-button-width;
  background-color: $search-box-button-bg-color;
  background-image: url($search-box-button-image);
  background-position: center;
  background-repeat: no-repeat;
}