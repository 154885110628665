.footer-navigation {
  padding: .125rem 0;
  
  > .nav {
    justify-content: center;
  }

  .nav-link {
    font-weight: $font-weight-bold;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}