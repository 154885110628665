.utility-navigation {
  padding: .125rem 0;

  @include media-breakpoint-up(lg) {
    order: 1;
  }

  > .nav {
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      justify-content: end;
    }
  }
}