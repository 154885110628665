.swiper-container {
  &.swiper-controls-outside {
    padding-left: 50px;
    padding-right: 50px;
  }
  &.swiper-pagination-outside {
    padding-bottom: 50px;
  }

  figure.figure,
  .figure-img {
    margin-bottom: 0;
  }

  .swiper-slide:not(.swiper-slide-visible):not(.swiper-slide-transitioning) {
    visibility: hidden;
  }
}
