.wf-block-icon-list {
  .icon-list {
    max-width: 475px;
    list-style: none;
    @include media-breakpoint-up(lg) {
      max-width: none;
    }

    .rte p:last-child {
      margin-bottom: 0;
    }

    li {
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }

  .icon-bullet-wrap {
    position: relative;
    width: 5.5rem;
    overflow: hidden;
    border-radius: 50%;
    margin: 2rem auto;

    .icon-bullet {
      width: 100%;
      padding-top: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-position: center center;
        object-fit: cover;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .icon-list {
      li {
        min-height: 90px;
        flex-direction: row;
        padding-left: 12rem;
        align-items: center;
      }
    }

    .icon-bullet-wrap {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 3rem;
      transform: translateY(-50%);
    }
  }
}