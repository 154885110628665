.card-footer {
  background: transparent;
}

.card-staff {
  .card-title {
    font-weight: bold;
  }
  &-top {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: (365rem/16);

    .card-staff-exec-img-wrap {
      transform: scale(1);
      transform-origin: center center;
      transition: 0.1s linear transform;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $blue;
        opacity: 0;
        transition: 0.1s linear opacity;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }

    &:hover,
    &:focus,
    &:focus-within {
      .card-staff-exec-img-wrap {
        transform: scale(0.9);
        &::after {
          opacity: 0.6;
        }
      }
      .card-staff-exec-overlay {
        opacity: 1;
      }
    }
  }
  &-overlay {
    position: absolute;
    color: $white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    opacity: 0;
    transition: 0.1s linear opacity;
  }
}
.card-staff-exec {
  &-top {
    max-width: (300rem/16);
  }
}