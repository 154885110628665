.card-top {
  position: relative;
}

.card-top-image {
  z-index: -1;
}

.card-top-text {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  padding: .25rem;
  margin: .5rem;
  z-index: 1;
}

.card-top-pretitle {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba($black, .9);
  color: $white;
}

.card-top-subtitle {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba($white, .9);
  color: $black;
}