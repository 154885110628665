$image-gallery-box-heights: ("4x3": percentage(3/4), "16x9": percentage(9/16)) !default; // Specify a value for each image gallery aspect ratio option
$image-gallery-main-border-color: $secondary !default;
$image-gallery-thumbnail-inactive-border-color: $gray-300 !default;
$image-gallery-thumbnail-active-border-color: $primary !default;

.image-gallery-box {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 0;

  // Use padding-top to achieve aspect ratio: https://css-tricks.com/aspect-ratio-boxes/
  padding-top: 100%; // Use default aspect ratio 1x1
  @each $ratio, $height in $image-gallery-box-heights {
    &-#{$ratio} {
      padding-top: $height;
    }
  }
}

img.image-gallery-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-gallery-box-main {
  outline: 1px solid $image-gallery-main-border-color;
}

.image-gallery-thumbnails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  list-style: none;
  margin: 0.25rem -0.25rem;
  padding: 0;
}

.image-gallery-thumbnail {
  position: relative;
  flex-basis: auto;
  width: 25%; // Display 4 thumbnails per row
  padding: 0.25rem 0.25rem;
}

.image-gallery-thumbnail-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.image-gallery-box-thumbnail {
  cursor: pointer;
  outline: 2px solid $image-gallery-thumbnail-inactive-border-color;
  opacity: 0.5;
}

.image-gallery-thumbnail-radio:checked + .image-gallery-box-thumbnail {
  outline: 2px solid $image-gallery-thumbnail-active-border-color;
  opacity: 1;
}

.image-gallery-box-thumbnail:hover {
  opacity: 1;
}