.main-navigation {
  padding: .125rem 0;

  @include media-breakpoint-up(lg) {
    order: 2;
  }

  > .navbar-nav {
    text-align: center;
    align-items: stretch;
    justify-content: end;

    @include media-breakpoint-up(lg) {
      text-align: left;
      align-items: center;
      justify-content: end;
    }
  }

  .dropdown-item {
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
    }

    .dropdown-toggle:hover {
      background: transparent;
    }
  }
  @include media-breakpoint-up('md') {
    .dropdown-submenu {
      padding-left: 1.5rem;
    }
  }
}