.vue-app .modal-wrap {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .6);
    z-index: 2000;
    &.active {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .youtube-modal {
        background-color: #FFF;
    }
    .modal-close-wrap {
        display: flex;
        justify-content: flex-end;
    }
    span.modal-close {
        img {
            height: 2rem;
            width: auto;
        }
    }
}
body {
    position: relative;
}