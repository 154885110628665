/*

Two-column Full-bleed content rows

This creates a new set of classes:
  .wrap-{breakpoint}-{columns}
  .container-{breakpoint}-{columns}

Wraps define columns divided by the standard bootstrap breakpoints and column
sizes, intended to be used in two-column layouts where content (e.g. images,
videos) extend to the edge of the window.

Containers are used for content inside of wraps that are expected to be bound
to the standard bootstrap content width.

This code does not assume order of columns, so you will likely have to manage
left/right margins on the container classes accordingly.

See templates/blocks/block-content-row.twig for usage examples.

*/
// Create temporary maps to manage various breakpoint widths
$container-grid: ();
$wrap-grid: ();

$container-half-max-widths: ();
$wrap-threequarter-max-widths: ();
$container-threequarter-max-widths: ();
$wrap-onequarter-max-widths: ();
$container-onequarter-max-widths: ();

@for $i from 1 through $grid-columns {
  $container-grid: map-merge(
    $container-grid,
    ($i: ())
  );
  $wrap-grid: map-merge(
    $wrap-grid,
    ($i: ())
  );
}
  
@each $breakpoint, $width in $container-max-widths {
  @for $i from 1 through $grid-columns {
    $container-grid: map-merge(
      $container-grid,
      ($i: map-merge(
        map-get($container-grid, $i),
        ($breakpoint: $width * $i / $grid-columns)
      ))
    );
    $wrap-grid: map-merge(
      $wrap-grid,
      ($i: map-merge(
        map-get($wrap-grid, $i),
        ($breakpoint: calc((100% - #{$width})/2 + #{$width} * #{$i} / #{$grid-columns}))
      ))
    );
  }

  $container-half-max-widths: map-merge(
    $container-half-max-widths,
    ($breakpoint: $width / 2)
  );

  $container-threequarter-max-widths: map-merge(
    $container-threequarter-max-widths,
    ($breakpoint: $width * 3 / 4)
  );
  $wrap-threequarter-max-widths: map-merge(
    $wrap-threequarter-max-widths,
    ($breakpoint: calc((100% - #{$width})/2 + #{$width} * 3 / 4))
  );
  $container-onequarter-max-widths: map-merge(
    $container-onequarter-max-widths,
    ($breakpoint: $width / 4)
  );
  $wrap-onequarter-max-widths: map-merge(
    $wrap-onequarter-max-widths,
    ($breakpoint: calc((100% - #{$width})/2 + #{$width} / 4))
  );

}

// For each breakpoint, make containers and wraps to manage bounding widths
@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  .container#{$infix}-half {
    @include make-container();
    @include make-container-max-widths($container-half-max-widths);
  }
  $container-half-max-widths: map-remove($container-half-max-widths, $breakpoint);

  .wrap#{$infix} {
    @include make-container();
  }
  
  .container-wrap#{$infix} {
    @include make-container();
  }
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    @for $i from 1 through $grid-columns {
      .wrap#{$infix}-#{$i} {
        @include make-container();
        @include make-container-max-widths(map-get($wrap-grid, $i));
      }
      .container#{$infix}-#{$i} {
        @include make-container();
        @include make-container-max-widths(map-get($container-grid, $i));
      }
    }
    
    .wrap#{$infix}-threequarter {
      @include make-container();
      @include make-container-max-widths($wrap-threequarter-max-widths);
    }
    .container#{$infix}-threequarter {
      @include make-container();
      @include make-container-max-widths($container-threequarter-max-widths);
    }
    .wrap#{$infix}-onequarter {
      @include make-container();
      @include make-container-max-widths($wrap-onequarter-max-widths);
    }
    .container#{$infix}-onequarter {
      @include make-container();
      @include make-container-max-widths($container-onequarter-max-widths);
    }
  }
}
