.vue-app {
    .vdp-datepicker input {
        width: 100%;
        background-color: $light;
        padding: .375rem .75rem;
        border: 1px solid $input-border-color;
        border-radius: .25rem;
    }
    .vdp-datepicker__clear-button {
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translateY(-50%);
    }
    .form-control, .custom-select {
        background-color: $light;
    }
    .form-control:disabled {
        background-color: darken($light, 20%);
        opacity: 0.8;
    }
    .location-btn {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
}
#clinical-trials-results-wrap, #events-results-wrap, #videos-results-wrap {
    background-image: url("../images/backgrounds/bg-curves-min.jpg");
    background-size: cover;
    .card-wrap {
        display: none;
        .btn {
            display: none;
        }
        &.active {
            display: block;
            .btn {
                display: block;
            }
        }
    }
}

.multiselect {
    &__single {
        background-color: transparent;
        font-size: $font-size-base;
        margin-bottom: 0;
        line-height: $input-line-height;
        padding-left: 0;
    }
    &__tags {
        background-color: $light;
        border-color: $input-border-color;
        font-size: $font-size-base;
        padding: $input-padding-y $input-padding-x;
        border-radius: 0;

        &-wrap {
            display: inline-flex;
            max-width: 100%;
            flex-wrap: wrap;
            grid-row-gap: 0.25rem;
        }
    }
    &__tag {
        background-color: $primary;
        margin-bottom: 0;
        &-icon {
            color: $white;
            &:hover {
                color: $white;
                background-color: darken($primary, 10%);
            }
        }
    }
    &__input {
        background-color: $light;
    }
    &__placeholder {
        color: $body-color;
        margin: 0;
        padding: 0;
    }
    &__option {
        &.multiselect__option--highlight {
            background-color: $primary;
            &::after {
                background-color: $primary;
            }
        }
        &--selected.multiselect__option--highlight {
            background-color: $danger;
            &::after {
                background-color: $danger;
            }
        }
    }
    &__input {
        font-size: $font-size-base;
        padding: 0;
        margin-bottom: 0;
        line-height: $input-line-height;
    }
    &--active {
        .multiselect__input {
            width: auto !important;
        }
    }
}

.location-btn{
    position: relative;

    &.using-geolocation {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        &::before {
            width: 0.75rem;
            height: 0.75rem;
            background: $white;
            border-radius: 50%;
            content: '';
            position: absolute;
            top: 50%;
            left: 0.5rem;
            transform: translateY(-50%);
        }
    }
}
.form-check.form-check-inline {
    line-height: 2.5rem;
}