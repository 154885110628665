.hero-carousel {
  &-item-background-wrapper:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.3);
  }
  &-item {
    padding-top: (100rem/16);
    padding-bottom: (100rem/16);
    @include media-breakpoint-up(md) {
      padding-top: (50rem/16);
      padding-bottom: 0;
    }
    &-title {
      line-height: 1.25;
      font-size: (35rem/16);
      font-weight: $font-weight-bold;
      @include media-breakpoint-up(md) {
        font-size: (50rem/16);
      }
      @include media-breakpoint-up(lg) {
        font-size: (60rem/16);
      }
      @include media-breakpoint-up(xl) {
        font-size: $h1-font-size;
      }
    }
    &-subtitle {
      @include font-size($h2-font-size);
      line-height: 1;
    }
    &-icons {
      justify-content: center;
      flex-wrap: nowrap;
    }
    &-icon {
      max-width: 350px;

      img {
        width: 67px;
        @include media-breakpoint-up(md) {
          width: 80px;
        }
        @include media-breakpoint-up(xl) {
          width: auto;
        }
      }

      &-label {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        @include font-size(15rem/16);

        @include media-breakpoint-up(md) {
          @include font-size(20rem/16);
        }
        @include media-breakpoint-up(xl) {
          @include font-size(25rem/16);
        }
      }
    }
  }

  .swiper-container {
    .swiper-button-prev, .swiper-button-next {
      color: $lt-blue;
      background: $white;
      border-radius: 50%;
      text-shadow: rgba(0,0,0,0.3) 0 0 10px;
      width: (50rem/16);
      height: (50rem/16);
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
      }

      @include media-breakpoint-up(xl) {
        width: (65rem/16);
        height: (65rem/16);
      }

      &:after {
        font-size: (30rem/16);
      }
    }
  }

  &-swipe-indicator {
    position: absolute;
    bottom: 5rem;
    left: 0;
    display: block;
    background: url("../images/icons/swipe.svg") center center no-repeat;
    width: 100%;
    height: 46px;
    z-index: 1;
    pointer-events: none;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  &-single {
    .hero-carousel-swipe-indicator {
      display: none;
    }
  }
}
.wf-block.wf-block-hero-carousel {
  .wf-block-appearance {
    padding-top: 0;
  }
  .hero-carousel-item-icon {
    a:not(.btn) {
      text-decoration: none;
      color: $white;
    }
  }
}
