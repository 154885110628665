$breakpoint_curve_height: (
    'xs': 1rem,
    'sm': 2rem,
    'md': 3rem,
    'lg': 4rem,
    'xl': 5rem,
    );

.clip-path {
    position: relative;
    overflow: hidden;

    &-ellipse {
        @each $bp, $ht in $breakpoint_curve_height {
            @include media-breakpoint-up($bp) {
                &-convex-square {
                    clip-path: polygon(
                        //convex top
                        0.00%  #{$ht * 5 / 5},
                        4.96%  #{$ht * 4.05 / 5},
                        9.87%  #{$ht * 3.20 / 5},
                        14.83% #{$ht * 2.45 / 5},
                        19.80% #{$ht * 1.80 / 5},
                        24.79% #{$ht * 1.25 / 5},
                        29.78% #{$ht * 0.80 / 5},
                        34.79% #{$ht * 0.45 / 5},
                        39.80% #{$ht * 0.20 / 5},
                        44.81% #{$ht * 0.05 / 5},
                        50.00% 0.00rem,
                        54.84% #{$ht * 0.05 / 5},
                        59.86% #{$ht * 0.20 / 5},
                        64.87% #{$ht * 0.45 / 5},
                        69.87% #{$ht * 0.80 / 5},
                        74.87% #{$ht * 1.25 / 5},
                        79.86% #{$ht * 1.80 / 5},
                        84.83% #{$ht * 2.45 / 5},
                        89.79% #{$ht * 3.20 / 5},
                        94.74% #{$ht * 4.05 / 5},
                        100.0% #{$ht * 5 / 5},
                        //square bottom
                        100% 100%,
                        0 100%
                        );
                    padding-top: $ht;
                }

                &-concave-square {
                    clip-path: polygon(
                        //concave top
                        0.00%  0.00rem,
                        4.96%  #{$ht * 0.95 / 5},
                        9.87%  #{$ht * 1.80 / 5},
                        14.83% #{$ht * 2.55 / 5},
                        19.80% #{$ht * 3.20 / 5},
                        24.79% #{$ht * 3.75 / 5},
                        29.78% #{$ht * 4.20 / 5},
                        34.79% #{$ht * 4.55 / 5},
                        39.80% #{$ht * 4.80 / 5},
                        44.81% #{$ht * 4.95 / 5},
                        50.00% #{$ht * 5 / 5},
                        54.84% #{$ht * 4.95 / 5},
                        59.86% #{$ht * 4.80 / 5},
                        64.87% #{$ht * 4.55 / 5},
                        69.87% #{$ht * 4.20 / 5},
                        74.87% #{$ht * 3.75 / 5},
                        79.86% #{$ht * 3.20 / 5},
                        84.83% #{$ht * 2.55 / 5},
                        89.79% #{$ht * 1.80 / 5},
                        94.74% #{$ht * 0.95 / 5},
                        100.0% 0.00rem,
                        //square bottom
                        100% 100%,
                        0 100%
                        );
                    padding-top: $ht;
                }

                &-square-square {
                    clip-path: none;
                }

                &-convex-convex {
                    clip-path: polygon(
                        //convex top
                        0.00%  #{$ht * 5 / 5},
                        4.96%  #{$ht * 4.05 / 5},
                        9.87%  #{$ht * 3.20 / 5},
                        14.83% #{$ht * 2.45 / 5},
                        19.80% #{$ht * 1.80 / 5},
                        24.79% #{$ht * 1.25 / 5},
                        29.78% #{$ht * 0.80 / 5},
                        34.79% #{$ht * 0.45 / 5},
                        39.80% #{$ht * 0.20 / 5},
                        44.81% #{$ht * 0.05 / 5},
                        50.00% 0.00rem,
                        54.84% #{$ht * 0.05 / 5},
                        59.86% #{$ht * 0.20 / 5},
                        64.87% #{$ht * 0.45 / 5},
                        69.87% #{$ht * 0.80 / 5},
                        74.87% #{$ht * 1.25 / 5},
                        79.86% #{$ht * 1.80 / 5},
                        84.83% #{$ht * 2.45 / 5},
                        89.79% #{$ht * 3.20 / 5},
                        94.74% #{$ht * 4.05 / 5},
                        100.0% #{$ht * 5 / 5},
                        //convex bottom
                        100.0% calc(100% - #{$ht * 5 / 5}),
                        94.74% calc(100% - #{$ht * 4.05 / 5}),
                        89.79% calc(100% - #{$ht * 3.20 / 5}),
                        84.83% calc(100% - #{$ht * 2.45 / 5}),
                        79.86% calc(100% - #{$ht * 1.80 / 5}),
                        74.87% calc(100% - #{$ht * 1.25 / 5}),
                        69.87% calc(100% - #{$ht * 0.80 / 5}),
                        64.87% calc(100% - #{$ht * 0.45 / 5}),
                        59.86% calc(100% - #{$ht * 0.20 / 5}),
                        54.84% calc(100% - #{$ht * 0.05 / 5}),
                        50.00% calc(100% - 0.00rem),
                        44.81% calc(100% - #{$ht * 0.05 / 5}),
                        39.80% calc(100% - #{$ht * 0.20 / 5}),
                        34.79% calc(100% - #{$ht * 0.45 / 5}),
                        29.78% calc(100% - #{$ht * 0.80 / 5}),
                        24.79% calc(100% - #{$ht * 1.25 / 5}),
                        19.80% calc(100% - #{$ht * 1.80 / 5}),
                        14.83% calc(100% - #{$ht * 2.45 / 5}),
                        9.87%  calc(100% - #{$ht * 3.20 / 5}),
                        4.96%  calc(100% - #{$ht * 4.05 / 5}),
                        0.00%  calc(100% - #{$ht * 5 / 5}),
                        );
                    padding-top: $ht;
                    padding-bottom: $ht;
                }

                &-concave-convex {
                    clip-path: polygon(
                        //concave top
                        0.00%  0.00rem,
                        4.96%  #{$ht * 0.95 / 5},
                        9.87%  #{$ht * 1.80 / 5},
                        14.83% #{$ht * 2.55 / 5},
                        19.80% #{$ht * 3.20 / 5},
                        24.79% #{$ht * 3.75 / 5},
                        29.78% #{$ht * 4.20 / 5},
                        34.79% #{$ht * 4.55 / 5},
                        39.80% #{$ht * 4.80 / 5},
                        44.81% #{$ht * 4.95 / 5},
                        50.00% #{$ht * 5 / 5},
                        54.84% #{$ht * 4.95 / 5},
                        59.86% #{$ht * 4.80 / 5},
                        64.87% #{$ht * 4.55 / 5},
                        69.87% #{$ht * 4.20 / 5},
                        74.87% #{$ht * 3.75 / 5},
                        79.86% #{$ht * 3.20 / 5},
                        84.83% #{$ht * 2.55 / 5},
                        89.79% #{$ht * 1.80 / 5},
                        94.74% #{$ht * 0.95 / 5},
                        100.0% 0.00rem,
                        //convex bottom
                        100.0% calc(100% - #{$ht * 5 / 5}),
                        94.74% calc(100% - #{$ht * 4.05 / 5}),
                        89.79% calc(100% - #{$ht * 3.20 / 5}),
                        84.83% calc(100% - #{$ht * 2.45 / 5}),
                        79.86% calc(100% - #{$ht * 1.80 / 5}),
                        74.87% calc(100% - #{$ht * 1.25 / 5}),
                        69.87% calc(100% - #{$ht * 0.80 / 5}),
                        64.87% calc(100% - #{$ht * 0.45 / 5}),
                        59.86% calc(100% - #{$ht * 0.20 / 5}),
                        54.84% calc(100% - #{$ht * 0.05 / 5}),
                        50.00% calc(100% - 0.00rem),
                        44.81% calc(100% - #{$ht * 0.05 / 5}),
                        39.80% calc(100% - #{$ht * 0.20 / 5}),
                        34.79% calc(100% - #{$ht * 0.45 / 5}),
                        29.78% calc(100% - #{$ht * 0.80 / 5}),
                        24.79% calc(100% - #{$ht * 1.25 / 5}),
                        19.80% calc(100% - #{$ht * 1.80 / 5}),
                        14.83% calc(100% - #{$ht * 2.45 / 5}),
                        9.87%  calc(100% - #{$ht * 3.20 / 5}),
                        4.96%  calc(100% - #{$ht * 4.05 / 5}),
                        0.00%  calc(100% - #{$ht * 5 / 5}),
                        );
                    padding-top: $ht;
                    padding-bottom: $ht;
                }

                &-square-convex {
                    clip-path: polygon(
                        //square top
                        0.00% 0%,
                        100% 0%,
                        //convex bottom
                        100.0% calc(100% - #{$ht * 5 / 5}),
                        94.74% calc(100% - #{$ht * 4.05 / 5}),
                        89.79% calc(100% - #{$ht * 3.20 / 5}),
                        84.83% calc(100% - #{$ht * 2.45 / 5}),
                        79.86% calc(100% - #{$ht * 1.80 / 5}),
                        74.87% calc(100% - #{$ht * 1.25 / 5}),
                        69.87% calc(100% - #{$ht * 0.80 / 5}),
                        64.87% calc(100% - #{$ht * 0.45 / 5}),
                        59.86% calc(100% - #{$ht * 0.20 / 5}),
                        54.84% calc(100% - #{$ht * 0.05 / 5}),
                        50.00% calc(100% - 0.00rem),
                        44.81% calc(100% - #{$ht * 0.05 / 5}),
                        39.80% calc(100% - #{$ht * 0.20 / 5}),
                        34.79% calc(100% - #{$ht * 0.45 / 5}),
                        29.78% calc(100% - #{$ht * 0.80 / 5}),
                        24.79% calc(100% - #{$ht * 1.25 / 5}),
                        19.80% calc(100% - #{$ht * 1.80 / 5}),
                        14.83% calc(100% - #{$ht * 2.45 / 5}),
                        9.87%  calc(100% - #{$ht * 3.20 / 5}),
                        4.96%  calc(100% - #{$ht * 4.05 / 5}),
                        0.00%  calc(100% - #{$ht * 5 / 5}),
                        );
                    padding-bottom: $ht;
                }

                &-convex-concave {
                    clip-path: polygon(
                        //convex top
                        0.00%  #{$ht * 5 / 5},
                        4.96%  #{$ht * 4.05 / 5},
                        9.87%  #{$ht * 3.20 / 5},
                        14.83% #{$ht * 2.45 / 5},
                        19.80% #{$ht * 1.80 / 5},
                        24.79% #{$ht * 1.25 / 5},
                        29.78% #{$ht * 0.80 / 5},
                        34.79% #{$ht * 0.45 / 5},
                        39.80% #{$ht * 0.20 / 5},
                        44.81% #{$ht * 0.05 / 5},
                        50.00% 0.00rem,
                        54.84% #{$ht * 0.05 / 5},
                        59.86% #{$ht * 0.20 / 5},
                        64.87% #{$ht * 0.45 / 5},
                        69.87% #{$ht * 0.80 / 5},
                        74.87% #{$ht * 1.25 / 5},
                        79.86% #{$ht * 1.80 / 5},
                        84.83% #{$ht * 2.45 / 5},
                        89.79% #{$ht * 3.20 / 5},
                        94.74% #{$ht * 4.05 / 5},
                        100.0% #{$ht * 5 / 5},
                        //concave bottom
                        100.0%  calc(100% - 0.00rem),
                        94.74%  calc(100% - #{$ht * 0.95 / 5}),
                        89.79%  calc(100% - #{$ht * 1.80 / 5}),
                        84.83%  calc(100% - #{$ht * 2.55 / 5}),
                        79.86%  calc(100% - #{$ht * 3.20 / 5}),
                        74.87%  calc(100% - #{$ht * 3.75 / 5}),
                        69.87%  calc(100% - #{$ht * 4.20 / 5}),
                        64.87%  calc(100% - #{$ht * 4.55 / 5}),
                        59.86%  calc(100% - #{$ht * 4.80 / 5}),
                        54.84%  calc(100% - #{$ht * 4.95 / 5}),
                        50.00%  calc(100% - #{$ht * 5 / 5}),
                        44.81%  calc(100% - #{$ht * 4.95 / 5}),
                        39.80%  calc(100% - #{$ht * 4.80 / 5}),
                        34.79%  calc(100% - #{$ht * 4.55 / 5}),
                        29.78%  calc(100% - #{$ht * 4.20 / 5}),
                        24.79%  calc(100% - #{$ht * 3.75 / 5}),
                        19.80%  calc(100% - #{$ht * 3.20 / 5}),
                        14.83%  calc(100% - #{$ht * 2.55 / 5}),
                        9.87%   calc(100% - #{$ht * 1.80 / 5}),
                        4.96%   calc(100% - #{$ht * 0.95 / 5}),
                        0.00%   calc(100% - 0.00rem)
                        );
                    padding-top: $ht;
                    padding-bottom: $ht;
                }

                &-concave-concave {
                    clip-path: polygon(
                        //concave top
                        0.00%  0.00rem,
                        4.96%  #{$ht * 0.95 / 5},
                        9.87%  #{$ht * 1.80 / 5},
                        14.83% #{$ht * 2.55 / 5},
                        19.80% #{$ht * 3.20 / 5},
                        24.79% #{$ht * 3.75 / 5},
                        29.78% #{$ht * 4.20 / 5},
                        34.79% #{$ht * 4.55 / 5},
                        39.80% #{$ht * 4.80 / 5},
                        44.81% #{$ht * 4.95 / 5},
                        50.00% #{$ht * 5 / 5},
                        54.84% #{$ht * 4.95 / 5},
                        59.86% #{$ht * 4.80 / 5},
                        64.87% #{$ht * 4.55 / 5},
                        69.87% #{$ht * 4.20 / 5},
                        74.87% #{$ht * 3.75 / 5},
                        79.86% #{$ht * 3.20 / 5},
                        84.83% #{$ht * 2.55 / 5},
                        89.79% #{$ht * 1.80 / 5},
                        94.74% #{$ht * 0.95 / 5},
                        100.0% 0.00rem,
                        //concave bottom
                        100.0%  calc(100% - 0.00rem),
                        94.74%  calc(100% - #{$ht * 0.95 / 5}),
                        89.79%  calc(100% - #{$ht * 1.80 / 5}),
                        84.83%  calc(100% - #{$ht * 2.55 / 5}),
                        79.86%  calc(100% - #{$ht * 3.20 / 5}),
                        74.87%  calc(100% - #{$ht * 3.75 / 5}),
                        69.87%  calc(100% - #{$ht * 4.20 / 5}),
                        64.87%  calc(100% - #{$ht * 4.55 / 5}),
                        59.86%  calc(100% - #{$ht * 4.80 / 5}),
                        54.84%  calc(100% - #{$ht * 4.95 / 5}),
                        50.00%  calc(100% - #{$ht * 5 / 5}),
                        44.81%  calc(100% - #{$ht * 4.95 / 5}),
                        39.80%  calc(100% - #{$ht * 4.80 / 5}),
                        34.79%  calc(100% - #{$ht * 4.55 / 5}),
                        29.78%  calc(100% - #{$ht * 4.20 / 5}),
                        24.79%  calc(100% - #{$ht * 3.75 / 5}),
                        19.80%  calc(100% - #{$ht * 3.20 / 5}),
                        14.83%  calc(100% - #{$ht * 2.55 / 5}),
                        9.87%   calc(100% - #{$ht * 1.80 / 5}),
                        4.96%   calc(100% - #{$ht * 0.95 / 5}),
                        0.00%   calc(100% - 0.00rem)
                        );
                    padding-top: $ht;
                    padding-bottom: $ht;
                }

                &-square-concave {
                    clip-path: polygon(
                        //square top
                        0.00% 0%,
                        100% 0%,
                        //concave bottom
                        100.0%  calc(100% - 0.00rem),
                        94.74%  calc(100% - #{$ht * 0.95 / 5}),
                        89.79%  calc(100% - #{$ht * 1.80 / 5}),
                        84.83%  calc(100% - #{$ht * 2.55 / 5}),
                        79.86%  calc(100% - #{$ht * 3.20 / 5}),
                        74.87%  calc(100% - #{$ht * 3.75 / 5}),
                        69.87%  calc(100% - #{$ht * 4.20 / 5}),
                        64.87%  calc(100% - #{$ht * 4.55 / 5}),
                        59.86%  calc(100% - #{$ht * 4.80 / 5}),
                        54.84%  calc(100% - #{$ht * 4.95 / 5}),
                        50.00%  calc(100% - #{$ht * 5 / 5}),
                        44.81%  calc(100% - #{$ht * 4.95 / 5}),
                        39.80%  calc(100% - #{$ht * 4.80 / 5}),
                        34.79%  calc(100% - #{$ht * 4.55 / 5}),
                        29.78%  calc(100% - #{$ht * 4.20 / 5}),
                        24.79%  calc(100% - #{$ht * 3.75 / 5}),
                        19.80%  calc(100% - #{$ht * 3.20 / 5}),
                        14.83%  calc(100% - #{$ht * 2.55 / 5}),
                        9.87%   calc(100% - #{$ht * 1.80 / 5}),
                        4.96%   calc(100% - #{$ht * 0.95 / 5}),
                        0.00%   calc(100% - 0.00rem)
                        );
                    padding-bottom: $ht;
                }
            }
        }
    }
}

.wf-block, .page-banner {
    position: relative;
    z-index: 2;
}

.wf-block.wf-block-clip-adjust-bottom {
    z-index: 1;

    & > .wf-block-appearance {
        @each $bp, $ht in $breakpoint_curve_height {
            @include media-breakpoint-up($bp) {
                padding-bottom: $ht;
                margin-bottom: -$ht;
            }
        }
    }
}
.wf-block.wf-block-clip-adjust-top {
    z-index: 1;

    & > .wf-block-appearance {
        @each $bp, $ht in $breakpoint_curve_height {
            @include media-breakpoint-up($bp) {
                padding-top: $ht;
                margin-top: -$ht;
            }
        }
    }
}

.wf-block-hero-carousel.wf-block-clip-adjust-bottom {
    .wf-block-appearance {
        padding-bottom: 0;
    }
    .clip-path-none {
        .hero-carousel {
            @each $ratio, $height in $hero-carousel-slide-heights {
                @each $bp, $ht in $breakpoint_curve_height {
                    @include media-breakpoint-up($bp) {
                        &-#{$ratio} {
                            padding-top: calc(#{$height} + #{$ht});

                            .hero-carousel-slides, .swiper-container, .hero-carousel-fixed-content {
                                margin-top: calc(-#{$height} - #{$ht}); // Offset slides equal to padding height
                            }
                        }
                    }
                }
            }
        }
    }
}
.clip-path-ellipse
{
    &-square,
    &-concave,
    &-convex {
        &-concave,
        &-convex {
            .hero-carousel {
                @each $ratio, $height in $hero-carousel-slide-heights {
                    @each $bp, $ht in $breakpoint_curve_height {
                        @include media-breakpoint-up($bp) {
                            &-#{$ratio} {
                                padding-top: calc(#{$height} + #{$ht});

                                .hero-carousel-slides, .swiper-container, .hero-carousel-fixed-content {
                                    margin-top: calc(-#{$height} - #{$ht}); // Offset slides equal to padding height
                                }
                            }
                        }
                    }
                }

                @each $bp, $ht in $breakpoint_curve_height {
                    @include media-breakpoint-up($bp) {
                        margin-bottom: -$ht;
                    }
                }
            }
        }
    }
}
.clip-path-ellipse {
    &-concave,
    &-convex {
        &-square,
        &-concave,
        &-convex {
            .content-row-side-full {
                @each $bp, $ht in $breakpoint_curve_height {
                    @include media-breakpoint-up($bp) {
                        margin-top: -$ht;
                    }
                }
            }
        }
    }
    &-square,
    &-concave,
    &-convex {
        &-concave,
        &-convex {
            .content-row-side-full {
                @each $bp, $ht in $breakpoint_curve_height {
                    @if $bp == "lg" or $bp == "xl" {
                        @include media-breakpoint-up($bp) {
                            margin-bottom: -$ht;
                        }
                    }
                }
            }
        }
    }
}
.wf-block.wf-block-clip-adjust-top {
    .content-row-side-full {
        @each $bp, $ht in $breakpoint_curve_height {
            @include media-breakpoint-up($bp) {
                margin-top: -$ht;
            }
        }
    }
}
.wf-block.wf-block-clip-adjust-bottom {
    .content-row-side-full {
        @each $bp, $ht in $breakpoint_curve_height {
            @if $bp == "lg" or $bp == "xl" {
                @include media-breakpoint-up($bp) {
                    margin-bottom: -$ht;
                }
            }
        }
    }
}
