/* Accordion-Tabs
   This component appears as an accordion on smallers responsive ranges, and appears as tabs on larger responsive ranges.

   This addresses the undesirable mobile appearance of Bootstrap's .nav-tabs, where the tabs wrap
   across multiple rows: https://getbootstrap.com/docs/4.3/components/navs/#tabs
*/

$accordion-tabs-breakpoint: "md" !default; // The collapse toggles appear as tabs at this breakpoint and above
$accordion-tabs-vertical-tab-width: 12rem !default; // Width of the tabs when displayed vertically
$accordion-tabs-toggles-border-width: $border-width !default; // Width of the border line beneath/aside the tab toggles
$accordion-tabs-vertical-margin-bottom: .25rem !default;

.accordion-tabs-toggles {
  display: none;
  
  @include media-breakpoint-up($accordion-tabs-breakpoint) {
    display: flex;
  }

  .nav-link:not(.collapsed) {
    background-color: $nav-tabs-link-active-bg;
    color: $nav-tabs-link-active-color;
    border-color: $nav-tabs-link-active-border-color;
    border-bottom-color: transparent;
  }
}

.accordion-tabs-card {
  @include media-breakpoint-up($accordion-tabs-breakpoint) {
    border: 0 !important;
    border-radius: 0;
  }
}

.accordion-tabs-card-header {
  @include media-breakpoint-up($accordion-tabs-breakpoint) {
    display: none;
  }
}

.accordion-tabs-collapse.collapsing {
  @include media-breakpoint-up($accordion-tabs-breakpoint) {
    transition-duration: 0.001s; // HACK: Hide collapsing height animation while respecting transition-dependent Bootstrap JS
  }
}

// Displays the tabs vertically aside the tab pane
.accordion-tabs-vertical {
  @include media-breakpoint-up($accordion-tabs-breakpoint) {
    display: flex;
  
    .accordion-tabs-toggles {
      flex-direction: column;
      justify-content: start;
      border-right: $accordion-tabs-toggles-border-width solid $nav-tabs-border-color;
      border-bottom: 0;

      .nav-item {
        margin: 0 (-$accordion-tabs-toggles-border-width) $accordion-tabs-vertical-margin-bottom 0 !important;
        width: $accordion-tabs-vertical-tab-width;

        .nav-link {
          text-align: left;
          border-bottom-color: transparent !important;
          border-right-color: $nav-tabs-border-color !important;
          border-radius: $border-radius 0 0 $border-radius;
          width: 100%;

          &:not(.collapsed),
          &:focus,
          &:hover {
            border-bottom-color: $nav-tabs-border-color !important;
            border-right-color: transparent !important;
          }
        }
      }
    }

    .accordion-tabs-collapses {
      background-color: $card-bg; // Since accordions use cards, use card background color
      width: 100%;
    }
  }
}