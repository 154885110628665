#header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  .header-navigation {
      background-color: rgba($lt-blue, 0.8);
  }
  .navbar-brand {
    min-height: 5.5rem;
    width: (240rem/16);
    transform-origin: left center;
    transform: scale(0.9);
    @include media-breakpoint-up(xl) {
      transform: scale(1);
    }
  }

  .nav-link {
    @include media-breakpoint-only(lg) {
      padding: 0.5rem 0.75rem;
    }
  }
}
