.main-navigation {
    width: 95%;
    @include media-breakpoint-up(xl) {
        width: 85%;
    }
    .navbar-nav a.nav-link {
        color: $white;
        font-size: (18rem/16);
        @include media-breakpoint-up(xl) {
            font-size: (22rem/16);
        }
    }
    .navbar-nav .nav-item {
        position: relative;
    }
    .navbar-nav .nav-item a {
        font-weight: bold;
        padding-left: 0;
        padding-right: 0;
        @include media-breakpoint-up(lg) {
            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0%;
                height: 5px;
                background-color: $gold;
                transition: .3s linear width;
            }
            &:hover {
                color: $white;
                &:before {
                    width: 100%;
                }
            }
            &:focus {
                color: $white;
                &:before {
                    width: 100%;
                }
            }
        }
    }
    .navbar-nav .dropdown-menu {
        background-color: unset;
        border: none;
        .nav-item {
            margin-left: 0px;
        }
    }
    .nav-pills .show>.nav-link, .nav-pills .nav-link {
        background-color: unset;
        color: $white;
        &:focus {
            color: $white;
        }
    }
}
.navbar-toggler {
    border: none !important;
    color: $white !important;
}
.mobile-nav {
    .main-navigation>.navbar-nav {
        text-align: left;
    }
}