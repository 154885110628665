// WordPress specific styles

// Wakefly Blocks
$block-padding-y: 2.5rem !default;

@each $alignment in ("left", "right", "center") {
  .wf-block-align-#{$alignment} {
    text-align: #{$alignment};
  }       
}

.wf-block-appearance {
  padding-top: $block-padding-y;
  padding-bottom: $block-padding-y;
}

@each $alignment in ("top", "center", "bottom") {
  .wf-block-bg-align-#{$alignment} {
    background-position: center #{$alignment};
  }
}
