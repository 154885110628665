#footer {
    background-color: $lt-gray;
    .footer-content {
        background-color: $dk-gray;
        color: $white;
        font-weight: 300;
    }

    .footer-logo {
        display: inline-block;
        width: 100%;
        max-width: (240rem/16);
    }
    .icon-footer {
        width: (40rem/16);
        height: (40rem/16);
    }
}