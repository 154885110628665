#alert-banner {
    opacity: 1;
    .alert-text {
        padding-left: 15px;
        padding-right: 15px;
        .btn {
            margin-left: 1rem;
            text-decoration: none;
            &:hover {
                cursor: pointer;
            }
        }
        p {
            margin-bottom: 0;
            display: inline-block;
        }
    }
    .close-btn-wrap {
        position: absolute;
        right: 15px;
    }
    #banner_dismiss {
        padding-right: 5px;
        opacity: 1;
        text-shadow: none;
        span {
            float: right;
        }
    }
}

.alert {
    p:last-child {
        margin-bottom: 0;
    }
}