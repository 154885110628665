#patient-story-results{
    background-image: url('../images/backgrounds/bg-curves-min.jpg');
    background-size: cover;
    .story-search-result {
        display: none;
        &.active {
            display: block;
        }
        .img {
            height: 20rem;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}