#main.search-results {
    .page-heading > .container {
        margin-top: 120px;
        h1 {
            font-size: 2rem;
            @include media-breakpoint-up(sm) {
                font-size: 2.5rem;
            }
            @include media-breakpoint-up(md) {
                font-size: 3rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 3.5rem;
            }
        }
    }
}