.listing-item-patient_story {
  .aspect-ratio-box-inner {
    overflow: hidden;
  }
  .listing-image {
    max-width: 300px;
    margin: auto;
    @include media-breakpoint-up(lg) {
      max-width: 540px;
    }
  }
}

.aspect-ratio-embed {
  position: absolute;
  top: 0;
  left: -39%;
  width: 178%; //retain 16x9 ratio, but adjust to fill height
  height: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
