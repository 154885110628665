.page-banner {
    padding-top: 10rem !important;
    position: relative;
    .h1 {
        font-weight: $font-weight-bold;
        font-size: (25rem/16);
        @include media-breakpoint-up(sm) {
          font-size: (30rem/16);
        }
        @include media-breakpoint-up(md) {
          font-size: (40rem/16);
        }
        @include media-breakpoint-up(lg) {
          font-size: (50rem/16);
        }
        @include media-breakpoint-up(xl) {
          font-size: (70rem/16);
        }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
    }
    .container {
      position: relative;
    }
}