nav.utility-navigation {
    ul li {
        width: 100%;
        position: relative;
    }
    ul li a {
        color: $white;
        text-transform: uppercase;
        font-size: (12rem/16);
        font-weight: $font-weight-bold;
        @include media-breakpoint-up(xl) {
            font-size: (14rem/16);
        }
    }
    ul li a.btn {
        padding-left: 1rem;
        margin-left: 1rem;
        padding-right: 1rem;
        border-radius: 0px;
        color: $dk-gray;
        text-transform: uppercase;
    }
    @include media-breakpoint-up(lg) {
        ul li:not(.button):not(:first-child):not(:last-child) {
            &:after {
                position: absolute;
                bottom: 10px;
                content: "";
                height: 16px;
                width: 1px;
                background-color: $white;
            }
        }
        ul li {
            width: auto;
        }
    }
    .search-field-container {
        position: relative;
    }
    #search-box-input, #mobi-search-box-input {
        @include media-breakpoint-up(lg){
            width: 0px !important;
            padding-left: 0;
            padding-right: 0;
            transition: all .2s;
            border: none;
            box-shadow: none;
            position: absolute;
            top: 2.5rem;
            right: -2rem;
            &:focus,
            &.active {
                width: 12rem !important;
                padding-left: .75rem;
                padding-right: .75rem;
                margin-left: 1rem;
                border-radius: 0px;
                box-shadow: $input-box-shadow, $input-focus-box-shadow;
            }
        }
    }
}
.search-box-button {
    background-color: unset;
    min-height: 26px;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    box-shadow: none;
    &:hover {
        box-shadow: none;
    }
    &:focus {
        box-shadow: none;
        border: 2px solid rgba(0,0,0,0.2);
    }
}