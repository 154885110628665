.patient-story {
  &-image {
    position: relative;

    &-overlay {
      background: rgba($lt-blue, 0.8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}