$accordion-card-header-bg: $light !default;
$accordion-collapse-toggle-color: $body-color !default;

.accordion-card {
  margin-bottom: .5rem;
}

.accordion-card-header {
  background-color: $accordion-card-header-bg;
  padding: 0 .5rem;
}

.accordion-collapse-toggle {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $accordion-collapse-toggle-color;

  &:after {
    content: "\002b";
    margin-left: 0.5rem;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    font-family: $font-family-monospace;
  }

  &:not(.collapsed):after {
    content: "\2212";
  }
}
