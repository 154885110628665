.wf-block-stat-bubbles {
  .button-bar ul {

  }
  .stat-bubbles {
    position: relative;
    width: 338px;
    height: 457px;
    @include media-breakpoint-up(md) {
      width: 456px;
      height: 538px;
    }
    // @include media-breakpoint-up(lg) {
    //   width: 540px;
    //   height: 730px;
    // }
    @include media-breakpoint-up(xl) {
      width: 627px;
      height: 739px;
      transform: translateX(25px);
    }
    display: none;
  }
  .stat-bubble {
    position: absolute;
    overflow: hidden;
    border-radius: 50%;
    width: 175px;
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $headings-font-weight;

    @include media-breakpoint-up(md) {
      width: 205px;
      height: 205px;
    }
    // @include media-breakpoint-up(lg) {
    //   width: 250px;
    //   height: 250px;
    // }
    @include media-breakpoint-up(xl) {
      width: 281px;
      height: 281px;
    }

    img {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      object-position: center center;
      object-fit: cover;
    }

    &_content {
      position: relative;
      z-index: 1;
      text-align: center;
      color: $white;
      text-shadow: 0 0 0 5px $dark;
      line-height: 1;
    }

    &_stat {
      font-size: (70rem/16);
      @include media-breakpoint-up(md) {
        font-size: (90rem/16);
      }
      // @include media-breakpoint-up(lg) {
      //   font-size: (100rem/16);
      // }
      @include media-breakpoint-up(xl) {
        font-size: (130rem/16);
      }
    }
    &_label {
      font-size: (15rem/16);
      @include media-breakpoint-up(md) {
        font-size: (20rem/16);
      }
      // @include media-breakpoint-up(lg) {
      //   font-size: (25rem/16);
      // }
      @include media-breakpoint-up(xl) {
        font-size: (28rem/16);
      }
    }
  }

  .stat-bubble-1 {
    width: 233px;
    height: 233px;
    top: 0;
    left: 0;

    @include media-breakpoint-up(md) {
      width: 275px;
      height: 275px;
    }
    // @include media-breakpoint-up(lg) {
    //   width: 340px;
    //   height: 340px;
    // }
    @include media-breakpoint-up(xl) {
      width: 378px;
      height: 378px;
    }

    .stat-bubble_stat {
      font-size: (80rem/16);
      @include media-breakpoint-up(md) {
        font-size: (100rem/16);
      }
      // @include media-breakpoint-up(lg) {
      //   font-size: (130rem/16);
      // }
      @include media-breakpoint-up(xl) {
        font-size: (150rem/16);
      }
    }
    .stat-bubble_label {
      font-size: (20rem/16);
      @include media-breakpoint-up(md) {
        font-size: (25rem/16);
      }
      // @include media-breakpoint-up(lg) {
      //   font-size: (35rem/16);
      // }
      @include media-breakpoint-up(xl) {
        font-size: (40rem/16);
      }
    }
  }

  .stat-bubble-2 {
    left: 213px;
    top: 204px;

    @include media-breakpoint-up(md) {
      top: 241px;
      left: 251px;
    }
    // @include media-breakpoint-up(lg) {
    //   top: 310px;
    //   left: 228px;
    // }
    @include media-breakpoint-up(xl) {
      top: 331px;
      left: 346px;
    }
  }

  .stat-bubble-3 {
    top: 282px;
    left: 6px;

    @include media-breakpoint-up(md) {
      top: 333px;
      left: 7px;
    }
    // @include media-breakpoint-up(lg) {
    //   top: 480px;
    //   left: 9px;
    // }
    @include media-breakpoint-up(xl) {
      top: 458px;
      left: 10px;
    }
  }

}
@media (min-width: 480px) {
  .wf-block-stat-bubbles {
    .stat-bubbles {
      display: block;
    }
  }
}