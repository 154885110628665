#news-featured-post {
    background-image: url('../images/backgrounds/bg-curves-min.jpg');
    background-size: cover;
    .img-container {
        max-height: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }
}