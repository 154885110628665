.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: $lt-blue;
  background: $white;
  border-radius: 50%;
  text-shadow: rgba(0,0,0,0.3) 0 0 10px;
  width: (40rem/16);
  height: (40rem/16);
  display: none;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    display: flex;
  }

  @include media-breakpoint-up(xl) {
    width: (52rem/16);
    height: (52rem/16);
  }

  &::after {
    font-size: (25rem/16);
    font-family: 'swiper-icons';
    letter-spacing: 0;
    font-variant: normal;
    line-height: 1;
  }
}
.carousel-control-prev-icon {
  &::after {
    content: 'prev';
  }
}
.carousel-control-next-icon {
  &::after {
    content: 'next'
  }
}
