.card-videos {
    .patient-education-video-thumbnail {
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
            background-image: url("../images/icons/YouTubePlayIcon.png");
        }
    }
}
.patient_education_video_embed {
    max-width: 100%;
    width: 50rem;
}