.mobile-nav {
    .navbar {
        padding-bottom: 0px;;
    }
    .main-navigation {
        padding: 0.125rem 1rem;
        .navbar-nav {
            .dropdown-menu .nav-item {
                width: 100%;
            }
            ul.dropdown-submenu {
                list-style-type: none;
            }
        }
        .dropdown-item {
            text-align: left;
        }
        .nav-link{
            &:hover {
                color: $white;
            }
            &:focus {
                color: $white;
            }
        }
        .nav-link.tier-1, .nav-link.tier-2, .nav-link.tier-3, .nav-link.tier-4, .nav-link.tier-5 {
            font-size: (18rem/16);
            ~ ul .nav-link {
                font-size: (14rem/16);
                font-weight: $font-weight-normal;
            }
        }
        .wrap-tier {
            margin-left: 1rem;
            padding-left: 0;
            &.wrap-tier-0 {
                margin-left: 0;
            }
        }
    }
    .utility-navigation {
        .button {
            padding-bottom: 1rem;
        }
    }
    .dropdown-toggle:after {
        border-style: solid;
        border-width: 0.15em 0.15em 0 0;
        border-right: .15em solid $white;
        content: '';
        display: inline-block;
        height: 0.65em;
        position: absolute;
        top: 12px;
        margin-left: 16px;
        transform: rotate(135deg);
        vertical-align: top;
        width: 0.65em;
        color: $white;
    }
    #mobile-mega-menu {
        .chevron:after {
            right: 2rem;
        }
    }

    .navbar-collapse {
        margin-left: -$grid-gutter-width/2;
        margin-right: -$grid-gutter-width/2;
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
    }
}