$hero-carousel-slide-heights: ( // Specify a value for each hero carousel slide aspect ratio option
  "3x1": percentage(1/3),
  "7x3": percentage(3/7),
  "16x9": percentage(9/16),
  "3x2": percentage(2/3)
) !default;

$hero-carousel-slide-vertical-paddings: (  // Specify a value for each hero carousel slide vertical padding option
  "small": 3rem,
  "medium": 5rem,
  "large": 8rem
) !default;

$hero-carousel-item-title-color: $headings-color !default;
$hero-carousel-item-title-font-family: $headings-font-family !default;
$hero-carousel-item-title-font-weight: $headings-font-weight !default;
$hero-carousel-item-title-font-size: $h1-font-size !default;
$hero-carousel-text-shadow: $white 0 0 3px, $white 0 0 3px, $white 0 0 3px, $white 0 0 3px !default;

.hero-carousel {
  position: relative;
  display: flex;
  align-items: stretch;

  // Use padding-top to achieve aspect ratio: https://css-tricks.com/aspect-ratio-boxes/
  // Aspect ratio gives the slides a MINIMUM height
  @each $ratio, $height in $hero-carousel-slide-heights {
    &-#{$ratio} {
      padding-top: $height;

      .hero-carousel-slides, .swiper-container, .hero-carousel-fixed-content {
        margin-top: -$height; // Offset slides equal to padding height
      }
    }
  }
}

.hero-carousel {
  .swiper-container {
    margin-top: 0;
  }
  .swiper-slide {
    height: 100%;
    min-height: 100%;
  }
  .hero-carousel-fixed-content {
    position: relative;
    height: auto;
  }
  .swiper-container {
    min-height: 100%;
  }
}

.hero-carousel-slides {
  width: 100%;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.hero-carousel-fixed-content {
  display: flex;
  align-items: stretch;
  width: 100%;
  z-index: 1; // Overlay fixed content above carousel items
  position:absolute;
  top:0;
  left:0;
  height:100%;
  pointer-events: none;

  .hero-carousel-item-content {
    pointer-events: auto;
  }
  &.hero-carousel-fixed-paged {
    padding-bottom: 40px;
  }
}

.hero-carousel-item-background-wrapper {
  position: absolute;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

img.hero-carousel-item-background,
video.hero-carousel-item-background {
  width: 100%;
  height: 100%;
}

.hero-carousel-item {
  //overflow: hidden;
}

.hero-carousel-item-content {
  position: relative;
  width: 70%;
  margin: auto;

  @each $size, $vertical-padding in $hero-carousel-slide-vertical-paddings {
    &-vertical-padding-#{$size} {
      padding: $vertical-padding 0;
    }
  }

  &-split {
    min-height: 100%;
    & > .row {
      min-height: 100%;
    }
    .hero-carousel-item-content-image {
      margin: auto !important;
    }
    .hero-carousel-item-content-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .hero-carousel-item-content-top {
      margin-bottom: 5rem;
    }
  }
}

.hero-carousel-item {
  display: flex !important; // Keep hero carousel items in document layout for flexbox-based item height
  align-items: center;
  visibility: hidden;
  position: relative;

  &.active,
  &.carousel-item-next,
  &.carousel-item-prev {
    visibility: visible;
  }
}

.hero-carousel-item-title {
  color: $hero-carousel-item-title-color;
  font-family: $hero-carousel-item-title-font-family;
  font-weight: $hero-carousel-item-title-font-weight;
  font-size: $hero-carousel-item-title-font-size;
  text-shadow: $hero-carousel-text-shadow;
  margin-bottom: 1rem;
}

.hero-carousel-item-subtitle {
  font-size: $font-size-lg;
  text-shadow: $hero-carousel-text-shadow;
}

.hero-carousel-item-description {
  text-shadow: $hero-carousel-text-shadow;
}

.hero-carousel-item-button-links {
  margin-top: 1rem !important;
}

.hero-carousel .swiper-container {
  width:100%;

  .swiper-slide .hero-carousel-item {
    visibility: visible;
  }

  .hero-carousel-item {
    min-height: 100%;
  }
  .swiper-button-prev, .swiper-button-next {
    color: #fff;
    text-shadow: rgba(0,0,0,0.3) 0 0 10px;
  }
  .swiper-pagination-bullet {
    width: 30px;
    height: 3px;
    border-radius: 1.5px;
    background: #fff;
  }
  &.swiper-pagination-outside {
    .swiper-pagination-bullet {
      background: #000;
    }
  }
  &.swiper-controls-outside {
    .swiper-button-prev, .swiper-button-next {
      color: #000;
      text-shadow: rgba(255,255,255,0.3) 0 0 10px;
    }
  }
}

