h1, .h1 {
  font-size: (35rem/16);
  @include media-breakpoint-up(md) {
    font-size: (50rem/16);
  }
  @include media-breakpoint-up(lg) {
    font-size: (60rem/16);
  }
  @include media-breakpoint-up(xl) {
    font-size: $h1-font-size;
  }
}
h2, .h2 {
  font-size: (30rem/16);
  @include media-breakpoint-up(lg) {
    font-size: (40rem/16);
  }
  @include media-breakpoint-up(xl) {
    font-size: $h2-font-size;
  }
}
h3, .h3 {
  font-size: (25rem/16);
  @include media-breakpoint-up(lg) {
    font-size: (30rem/16);
  }
  @include media-breakpoint-up(xl) {
    font-size: $h3-font-size;
  }
}
h4, .h4 {
  font-size: (18rem/16);
  @include media-breakpoint-up(lg) {
    font-size: (20rem/16);
  }
  @include media-breakpoint-up(xl) {
    font-size: $h4-font-size;
  }
  line-height: 1.1667;
}
h5, .h5 {
  font-size: (1rem);
  @include media-breakpoint-up(lg) {
    font-size: (20rem/16);
  }
  @include media-breakpoint-up(xl) {
    font-size: $h5-font-size;
  }
  line-height: 1.2;
}
h6, .h6 {
  font-size: (0.9rem);
  @include media-breakpoint-up(lg) {
    font-size: (18rem/16);
  }
  @include media-breakpoint-up(xl) {
    font-size: $h6-font-size;
  }
  line-height: 1.25;
}

.h7 {
  font-size: (0.9rem);
  @include media-breakpoint-up(lg) {
    font-size: (18rem/16);
  }
  line-height: 1.25;
  font-weight: $font-weight-bolder;
}

.wf-block {
  a:not(.btn),
  a.btn.btn-link {
    text-decoration: underline;
  }
  a.btn.btn-link:focus {
    outline-width: 2px;
    outline-style: solid;
  }
}

p {
  margin-bottom: 1.5em;
}

blockquote {
  background: $light;
  padding: ($spacer * 1.5) ($spacer * 3);

  p:last-child {
    margin-bottom: 0;
  }
}

.aspect-ratio-box iframe {
  width: 100%;
  height: 100%;
  border: none;
}

p.large {
  font-size: (22rem/16);
}

.vue-search-results {
  scroll-margin-top: 200px;
}