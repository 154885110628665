.card-testimonial {
  text-align: center;

  .card-top {
    padding: 2rem 2rem 0;
  }
}

.listing-item-testimonial {
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.testimonial-content {
  padding: 0.5rem 0;
}

.testimonial-author-name {
  display: block;
  font-weight: $font-weight-bold;
}

.testimonial-author-title {
  display: block;
}