.card.card-events {
    background: unset;
    .card-img-top {
        height: 10rem;
        width: auto;
    }
    .card-link {
        display: inline-block;
    }

    .event-past-label {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}