$sidebar-margin-y: 0 !default;
$sidebar-margin-x: 1rem !default;
$sidebar-padding-y: $block-padding-y !default;
$sidebar-padding-x: 0rem !default;
$sidebar-bg-color: translucent !default;
$sidebar-widths: ("xs": 100%, "xl": 30%) !default;
$sidebar-widget-margin-bottom: 1rem !default;
$sidebar-title-font-size: $h4-font-size !default;

.sidebar-page-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1*($grid-gutter-width / 2);

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

@each $breakpoint, $width in $sidebar-widths {
  @include media-breakpoint-up($breakpoint) {
    .sidebar-page-main-content {
      flex: 1 1 (100% - $width);
    }

    .sidebar-page-sidebar {
      flex: 1 1 $width;
    }
  }
}

.sidebar {
  margin: $sidebar-margin-y $sidebar-margin-x;
  padding: $sidebar-padding-y $sidebar-padding-x;
  background-color: $sidebar-bg-color;
  height: 100%;

  .widget {
    margin-bottom: $sidebar-widget-margin-bottom;
  }

  h2.widget-title {
    font-size: $sidebar-title-font-size;
  }
}