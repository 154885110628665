// Standard styles for commonly-used HTML elements and framework classes

// Buttons
button {
  background-color: initial;
}

// Figures
figure, .figure {
  display: block;
}

.rounded-pill {
  overflow: hidden;
}