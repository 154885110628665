$stat-item-value-color: $primary !default;
$stat-item-label-color: $body-color !default;
$stat-item-divider-color: $dark !default;
$stat-item-value-font-size: 3rem !default;
$stat-item-label-font-size: 1rem !default;

.stats-row {
  clip-path: inset(0 1px); // Hide stat-item vertical dividers at row ends
  margin: 0;
}

.stats-col {
  margin-bottom: 1rem;
  padding: 0;

  &:last-child {
    clip-path: inset(0 1px); // Hide stat-item vertical divider on last item
  }
}

.stat-item {
  text-align: center;
  padding: 0 1rem;
  height: 100%;
  border-right: 1px solid $stat-item-divider-color;
}

.stat-item-value {
  color: $stat-item-value-color;
  font-size: $stat-item-value-font-size;
  font-weight: $font-weight-bolder;
  overflow-wrap: normal;
}

.stat-item-label {
  color: $stat-item-label-color;
  font-size: $stat-item-label-font-size;
  font-weight: $font-weight-bold;
}