.location-icon {
    position: relative;
    width: (34rem/16);
    height: (49rem/16);
    line-height: (34rem/16);
    white-space: nowrap;
    padding-bottom: (18rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../images/icons/location-icon.png') center center no-repeat;
    color: $white;
    font-weight: $font-weight-bold;
}

.location-result {
    position: relative;
    padding-left: 3rem;
    padding-top: 1rem;
    .location-icon {
        position: absolute;
        top: 1rem;
        left: 0rem;
    }
    .location-title {
        line-height: 2.5rem;
    }
    .location-address address {
        font-weight: normal;
    }
}

.location-search-results {
    height: 66rem;
    overflow-y: auto;
}

.locations-gmap {
    width: 100%;
    height: 100%;
}

.location-result-bubble {
    font-family: $font-family-sans-serif;
    .location-address address {
        font-weight: normal;
    }
}

.location-listing-post {
    background-color: $blue;
    color: $white;
    .article-date {
        font-size: 1rem;
    }
    .article-title a.listing-item-title {
        color: $white;
        text-decoration: none;
        font-size: 1.1rem;
        font-weight: 600;
    }
    a.btn-link.read-more {
        background-color: $white;
        text-decoration: none !important;
        color: $blue;
    }
}