.event-date,
.event-time {
  position: relative;
  padding-left: 2.5rem;
  line-height: 2rem;
  &::before {
    content: '';
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    background: center center no-repeat;
    background-size: contain;
  }
}
.event-date::before {
  background-image: url("../images/icons/date/Date@2x.png");
}
.event-time::before {
  background-image: url("../images/icons/date/Time@2x.png");
}
