// Animation keyframes

@keyframes slide-in-left {
  from {
    left: -100%;
  }

  to {
    left: 0;
  }
}

@keyframes slide-out-left {
  from {
    left: 0;
  }

  to {
    left: -100%;
  }
}

@keyframes slide-in-right {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}

@keyframes slide-out-right {
  from {
    left: 0;
  }

  to {
    left: 100%;
  }
}