/** Style Guide Overrides **/
$accordion-card-header-bg: $light !default;
$accordion-collapse-toggle-color: $body-color !default;

.accordion-card {
  margin-bottom: .5rem;
  border: none;
}

.accordion-card-header {
  background-color: unset;
  padding: 0 .5rem;
  border: none;
  border-radius: 0px;
}

.accordion-collapse-toggle {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $white;
  background-color: $blue;
  border: none;
  border-radius: 0px;
  &:hover{
    color: $white;
  }
  &.collapsed {
    background-color: unset;
    color: $accordion-collapse-toggle-color;
  }
  &:focus {
    box-shadow: none;
  }

  &:after {
    content: "\002b";
    margin-left: 0.5rem;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    font-family: $font-family-monospace;
  }

  &:not(.collapsed):after {
    content: "\2212";
  }
}
