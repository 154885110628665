#mega-menu, #mobile-mega-menu {
    background: linear-gradient(90deg, rgba(38,97,150,0.9) 0%, rgba(86,134, 184, 0.9) 30%, rgba(66,156,216,0.9) 100%);

    &.active {
        display: block;
    }
    .mega-sidebar {
        background-color: rgba(0,96,154,0.2);
    }
    .mega-sub-menu {
        .nav-link {
            font-size: 1.25rem;
        }
    }
    .mega-menu-sidebar-nav {
        @include media-breakpoint-up(lg){
            // padding-right: 2rem;
            // padding-left: 3rem;
            .nav-item {
                margin-right: 3rem;
            }
        }
        .nav-item, .mega-menu {
            display: block;
            width: 100%;
            .nav-link {
                width: 100%;
                color: $white;
                position: relative;
                font-size: (17rem/16);
                font-weight: $font-weight-bold;
                @include media-breakpoint-down(sm){
                    padding-left: 0px;
                }
            }
        }
        .chevron::after {
            border-style: solid;
            border-width: 0.25rem 0.25rem 0 0;
            content: '';
            display: inline-block;
            height: (14rem/16);
            right: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            vertical-align: top;
            width: (14rem/16);
            color: $white;
        }
    }
    .mega-menu {
        .nav {
            display: none;
            &.mega-sub-menu {
                display: block;
            }
            &.active {
                display: block;
            }
            .nav-item {
                .nav-link {
                    width: 100%;
                    color: $white;
                    font-size: (17rem/16);
                    font-weight: $font-weight-bold;
                    padding-left: 0;
                }
            }
        }
        .nav-heading {
            span {
                color: $white;
                font-size: (22rem/16);
                font-weight: $font-weight-bold;
            }
        }
    }
    .mega-tab {
        display: none;
        &.active {
            display: flex;
        }
    }
}
#mobile-mega-menu {
    overflow: auto;
    height: calc(100vh - 5.5rem);
}
.mega-menu-container {
    // background-color: rgba(#009DDC, 0.8);
}
#mega-menu {
    display: none;
}