.location-address {
  address {
    font-weight: bold;
  }
}
.location-people {
  .card {
    background: transparent;
  }

  &-toggle {
    position: relative;
    width: 100%;
    line-height: 2rem;
    font-weight: $font-weight-bold;
    font-size: $h5-font-size;
    display: flex;
    align-items: center;
    grid-column-gap: 1rem;

    .icon {
      position: relative;
      width: 2rem;
      height: 2rem;
      background: $primary;
      top: 0;
      left: 0;

      &::before,
      &::after {
        background: $white;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &::before {
        width: 1rem;
        height: (1rem/4);
      }
      &::after {
        width: (1rem/4);
        height: (1rem/4);
        transition: 0.2s linear height;
      }
    }

    &.collapsed {
      .icon::after {
        height: 1rem;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none !important;
    }
  }
  
  .wf-block-appearance {
    padding: 0;
  }
}
.service-list {
  list-style: none;
  padding: 0;
  li {
    position: relative;
    padding-left: 2.5rem;
    line-height: 2rem;
  }
  .service-icon {
    position: absolute;
    display: block;
    vertical-align: middle;
    top: 0.5rem;
    left: 0.5rem;
    width: 1rem;
    height: 1rem;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }
}