.card-wrap {
    display: none;
    .btn {
        display: none;
    }
    &.active {
        display: block;
        .btn {
            display: block;
        }
    }
    .card.card-news {
        background: unset;
        .card-img-top {
            height: 10rem;
            width: auto;
        }
        .card-link {
            display: inline-block;
        }
        .card-text {
            font-size: 14px;
        }
        .img-bg {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 10rem;
        }
        .btn {
            display: inline-block;
        }
    }
}