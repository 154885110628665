// Specify dimensions for each aspect ratio option. Used by image gallery, cards, thumbnails, etc.
$aspect-ratio-dimensions: (
  "1x1":  ("width": 1,  "height": 1),
  "1x2":  ("width": 1,  "height": 2),
  "3x4":  ("width": 3,  "height": 4),
  "2x1":  ("width": 2,  "height": 1),
  "4x3":  ("width": 4,  "height": 3),
  "16x9": ("width": 16, "height": 9),
);

// The box element applies the aspect ratio
.aspect-ratio-box {
  overflow: hidden;
  position: relative;
  padding: 0;
  width: 100%;
  height: 0;

  // Use padding-top to achieve aspect ratio: https://css-tricks.com/aspect-ratio-boxes/
  padding-top: 100%; // Use default aspect ratio 1x1
  @each $ratio, $dimensions in $aspect-ratio-dimensions {
    &-#{$ratio} {
      padding-top: percentage(map-get($dimensions, "height") / map-get($dimensions, "width"));
    }
  }

  // Don't use aspect ratio box styles on the "none" option
  &-none {
    padding-top: 0;
    max-width: 100%;
    width: auto;
    height: auto;
    text-align: center;

    > .aspect-ratio-box-inner {
      position: initial;
      top: initial;
      max-width: 100% !important;
      width: auto !important;
      height: auto !important;
    }
  } 
}

// The inner element which should be affected by the aspect ratio (e.g., the image)
.aspect-ratio-box-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
