$swiper-arrow-color: $black !default;

.swiper-button-next, .swiper-button-prev {
  color: $swiper-arrow-color;
}

.swiper-slide {
  height: auto;
}

.swiper-wrapper-align-center {
  align-items: center;
}