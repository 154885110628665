$content_row_full_bleed_padding_base: 3rem !default;
$content_row_sizes: ("less": $content_row_full_bleed_padding_base / 2, "normal": $content_row_full_bleed_padding_base, "more": $content_row_full_bleed_padding_base * 2) !default;

.content-row {
  &-side-full {
    min-height: 300px;
    background-size: cover;
    background-position: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.content-row_full .content-row-main .container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@include media-breakpoint-up(lg) {
  @each $spec, $size in $content_row_sizes {
    .content-row_full.space-#{$spec} .content-row-main .container {
      padding-top: $size;
      padding-bottom: $size;
    }
  }
}