.addtoany_list .a2a_svg,
.a2a_mini_services .a2a_svg,
.a2a_full_services .a2a_svg {
  border-radius: 50% !important;
  overflow: hidden;
  background: $dk-gray;
  padding: 2px;
}
body .a2a_menu a {
  &, &.a2a_more {
    color: $body-color;
    text-decoration: underline;
  }
}