.review-stars {
  display: block;
  text-decoration: none !important;
  font-size: 0;
  .review-star {
    width: 34px;
    height: 28px;
    background: url('../images/icons/Rate-Star.png') center center no-repeat;
    display: inline-block;
  }
  .review-star:hover ~ .review-star {
    opacity: 0.25;
  }
}

.staff {
  &-image {
    max-width: (350rem/16);
    margin: auto;
  }
  &-position {
    @include font-size(35rem/16);
    font-weight: $font-weight-normal;
  }
  &-treatments,
  &-languages,
  &-locations {
    &::before {
      content: '';
      display: block;
      width: 90px;
      height: 90px;
      background-color: $blue;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 58px auto;
      border-radius: 50%;
      margin: 0 auto 0.75rem;
    }
    ul {
      list-style: none;
      padding: 0;
    }
  }
  &-telemedicine {
    @include media-breakpoint-down(md) {
      margin-bottom: 1.5rem;
    }
  }
  &-treatments::before {
    background-image: url('../images/icons/icon-first-aid.svg');
  }
  &-languages::before {
    background-image: url('../images/icons/icon-conversation.svg');
  }
  &-locations::before {
    background-image: url('../images/icons/icon-map.svg');
  }

  &-content_section {
    h3 {
      @extend .h5;
    }
  }
}